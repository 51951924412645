<div [ngClass]="{'side-filtter': translate.is_arabic, 'side-filtterEn': !translate.is_arabic}">
    <div class="col-12 col-md-3">
      <app-store-side-menu></app-store-side-menu>
    </div>
    <div class="col-12 col-md-9">
      <app-store-data [products]="products"></app-store-data>
    </div>
  <router-outlet></router-outlet>
</div>

