<div class="row" >
  <h4 class="nodata" *ngIf="productsData?.length == 0"> {{translate.is_arabic ? 'لا يوجد داتا لعرضها' : 'No Data To Show'}}</h4>
   <div class="col-12 col-md-6 col-lg-4" *ngFor = "let product of productsData ">
      <div class="product" >
          <div class="btns-div">
              <button (click)="handelHeart(product)" class="btttn heartBtn"  *ngIf="!product.is_Favorite">
              </button>
              <button (click)="handelHeart(product)" class="btttn disheartBtn" *ngIf="product.is_Favorite">
              </button>
              <button class="btttn shareBtn" (click)="copyLink()" ngxClipboard [cbContent]="navUrl">
              </button>
          </div>
        <!--
          <div class="product-img">
              <img src={{sharedService.ipRootImg+product?.imagePath[0].imagePath}} loading="lazy"/>
          </div>
-->
          <div class="description">
            <div class="d-flex flex-wrap justify-content-between">
              <p>{{product ? product[translate.data.requestdData.genName] : 'N/A' }}</p>
              <p>  <star-rating [value]="product?.rate"
                [totalstars]="5"
                checkedcolor="#ed6c37"
                uncheckedcolor="rgb(224, 224, 224)"
                size="18px"
                [readonly]="true">
              </star-rating></p>
            </div>
              <h2>{{product?.salePrice}} {{translate.data.caravanCard.coin}} / {{translate.data.caravanCard.night}}</h2>
          </div>

          <button (click)="onhandle(product.id)">{{translate.is_arabic ? 'إضافه': 'ADD'}}</button>
      </div>
  </div>

</div>
