import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { StoreComponent } from './store.component';
import { StoreSideMenuComponent } from './store-side-menu/store-side-menu.component';
import { StoreDataComponent } from './store-data/store-data.component';
import {SliderModule} from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { PagesSharedModule } from '../pagesShared.module';
import { StoreProductComponent } from './store-product/store-product.component';


@NgModule({
  declarations: [StoreComponent, StoreSideMenuComponent, StoreDataComponent, StoreProductComponent],
  imports: [
    CommonModule,
    StoreRoutingModule,
    SliderModule,
    FormsModule,
    PagesSharedModule
  ]
})
export class StoreModule { }
