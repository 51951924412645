import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-store-side-menu',
  templateUrl: './store-side-menu.component.html',
  styleUrls: ['./store-side-menu.component.scss']
})
export class StoreSideMenuComponent implements OnInit {

  //slider length
  length1:any;
  length2:any;

  //checkboxes price
  price1:any;
  price2:any;

  //range slider 
  rangeValues: number[];
  rangeValues2: number[];

  //own product
  Is_Rent: boolean;
  Is_Sale: boolean;

  val:number = 0;
  val1:number = 1;
  val2:number = 2;
  val3:number = 3;
  val4:number = 4;
  val5: number = 5;

  isCheckedRate0: boolean = false;
  isCheckedRate1: boolean = false;
  isCheckedRate2: boolean = false;
  isCheckedRate3: boolean = false;
  isCheckedRate4: boolean = false;
  isCheckedRate5: boolean = false;

  //carvan type
  categories:Array<number> =[];

  //response caravan types
  serviceTypesResponse :any;

  constructor(
    private router: Router ,
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    public sharedService: SharedService
  ) { }

  ngOnInit(): void {
    // get products type
    this.pagesService.getProductsCategories().subscribe(
      (response: any) => {
        this.serviceTypesResponse = response.data.map((item: any) => ({...item, active: false})); //to add active boolean
        console.log('getServicesType =====> ', this.serviceTypesResponse);
      },
      (error:any) => {
        console.log(error);
      }
    )
  }

  //handel click on karavan type
handleType(caravanType: any) {
  caravanType.active = !caravanType.active;
  const index = this.categories.findIndex(ele => ele === caravanType.id);
  console.log('index', index);
  if(caravanType.active && index == -1) {
    this.categories.push(caravanType.id);
  } else {
    console.log('karavan id in in array already !');
    this.categories.splice(index, 1);
  }
  console.log(this.categories);
  if(this.categories.length > 0) {
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
      Categories: this.categories
    };
  } else {
    delete this.pagesService.searchObjectProducts.Categories;
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
    };
  }
  this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
}

  //handel all type of karavan
handleAllType() {
  console.log(this.serviceTypesResponse);
  this.categories = []; //empty karavan ever click
  this.serviceTypesResponse.forEach((service: any) => {
    service.active = true;
    if(service.active) {
      this.categories.push(service.id);
    };
  });
  console.log(this.categories);
  if(this.categories.length > 0) {
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
      Categories: this.categories
    };
  } else {
    delete this.pagesService.searchObjectProducts.Categories;
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
    };
  }
 
  this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
};

  sliderPrice() {
    this.price1 = this.rangeValues2[0];
    this.price2 = this.rangeValues2[1];
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
      PriceFrom: this.price1,
      PriceTo: this.price2
    };
    this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
  }

  handlePrice() {
    this.rangeValues2 = [this.price1,this.price2];
  }


//change handelChangeOwnProduct 
handelChangeOwnProduct(type: string) {
  if(type == 'rent') {
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
      Is_Rent: this.Is_Rent
    }
  }

  if(type == 'sale') {
    this.pagesService.searchObjectProducts = {
      ...this.pagesService.searchObjectProducts,
      Is_Sale: this.Is_Sale
    }
  }

  this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);

}

  handelChangeRate(rateCheck: boolean, type: string) {
    console.log(rateCheck, type);
    if(!rateCheck) {
      delete this.pagesService.searchObjectProducts.rate;
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
      return;
    };
    //add rate of 0 to search object
    if(rateCheck && type == 'first') {
      this.pagesService.searchObjectProducts = {
        ...this.pagesService.searchObjectProducts,
        rate: this.val
      };
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
    }

    //add rate of 1 to search object
    if(rateCheck && type == 'second') {
      this.pagesService.searchObjectProducts = {
        ...this.pagesService.searchObjectProducts,
        rate: this.val1
      };
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
    }

     //add rate of 2 to search object
    if(rateCheck && type == 'third') {
      this.pagesService.searchObjectProducts = {
        ...this.pagesService.searchObjectProducts,
        rate: this.val2
      };
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
    }

    //add rate of 3 to search object
    if(rateCheck && type == 'fourth') {
      this.pagesService.searchObjectProducts = {
        ...this.pagesService.searchObjectProducts,
        rate: this.val3
      };
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
    }
    //add rate of 4 to search object
    if(rateCheck && type == 'five') {
      this.pagesService.searchObjectProducts = {
        ...this.pagesService.searchObjectProducts,
        rate: this.val4
      };
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
    }

    //add rate of 5 to search object
    if(rateCheck && type == 'six') {
      this.pagesService.searchObjectProducts = {
        ...this.pagesService.searchObjectProducts,
        rate: this.val5
      };
      this.pagesService.filterSearchProductObject.next(this.pagesService.searchObjectProducts);
    }

  }

}
