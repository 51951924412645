import { Component, OnInit , Output, EventEmitter, Input  } from '@angular/core';
import { TranslationService } from 'src/app/shared/translation.service';
import {ActivatedRoute, Params, Router } from '@angular/router';
import { PagesService } from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-store-product',
  templateUrl: './store-product.component.html',
  styleUrls: ['./store-product.component.scss']
})
export class StoreProductComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter<number>();

  constructor(
    private router: Router ,
    public translate: TranslationService,
    private pagesService: PagesService,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
  ) { }

  @Input('productsData') productsData: any[];


  navUrl: string;
  data:any;
  reserveId: number;


  ngOnInit ():void {
    //catch reserve id
    this.route.params.subscribe(
      (params: Params) => {
        this.reserveId = params['reserveId'];
      }
    );
  };

  onhandle=(id:any)=>{
    this.router.navigate(['../../products/'+ id + '/' + this.reserveId], {relativeTo: this.route});
  }

  //on copy link url
  copyLink() {
    this.navUrl = window.location.href;
    this.sharedService.stringErrorMessage.next('تم نسخ الرابط');
    this.sharedService.errorShowMessage.next(true);
    this.sharedService.hideErrorMessage();
  }

  //handel heart click
  handelHeart(item: any) {
    console.log('karavan => ', item);
    item.isFavorite = !item.isFavorite;
    console.log(item.isFavorite);
    const data = {
      productId: item.id,
      isFavorite: item.isFavorite
    };

    //set favpurite 
    this.pagesService.setProductFavourite(data).subscribe(
      (response: any) => {
        console.log(response)
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
