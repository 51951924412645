
<div [ngClass]="{'search-result': translate.is_arabic, 'search-resultEn': !translate.is_arabic}">
  <div class="header-result sticky-top">
      <div class="row">
          <div class="col-12 col-md-4">
              <p>{{translate.data.store.campingEquipment}} <span>  {{translate.data.store.total}} : {{products?.length}}</span></p>
          </div>
          <div class="col-12 col-md-6">
              <div class="row">
                 <!--  <div class="col-12 col-md-4">
                      <div class="form-check p-0">
                          <label class="form-check-label" style="margin: 0 10px;" for="flexCheckDefault">
                               {{translate.data.result.moreBooking}}
                            </label>
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      </div>
                  </div> -->
                 <!-- <div class="col-12 col-md-4">
                      <p-dropdown [options]="rangesData"
                          (onChange)="changePrice()"
                          [(ngModel)]="selectedOptionSalary"
                          optionLabel="name_ar"
                          optionValue="value"
                          styleClass="customClass"></p-dropdown>
                          <img class="arrow-icon" src="../../../../assets/Path 6.png" style="position: absolute;top: 8px;right: 125px;" />
                 </div> -->
               <!--   <div class="col-12 col-md-4">
                  <p-dropdown [options]="optionsNearData"
                  [(ngModel)]="nearOption"
                   optionLabel="name_ar" optionValue="value" styleClass="customClass"></p-dropdown>
                   <img class="arrow-icon" src="../../../../assets/Path 6.png" style="position: absolute;top: 8px; right: 110px;" />
                 </div> -->
              </div>
          </div>
          <!-- <div class="col-12 col-md-2">
              <p class="showMap">{{translate.data.result.showMap}}</p>
          </div> -->

      </div>
  </div>



  <div class="karavan-result">
      <app-store-product [productsData]="products"></app-store-product>
  </div>


</div>
