import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-store-data',
  templateUrl: './store-data.component.html',
  styleUrls: ['./store-data.component.scss']
})
export class StoreDataComponent implements OnInit {

  @Input('products') products: any[];

  constructor(private pagesService: PagesService,
    public translate: TranslationService,
     private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.pagesService.getProducts().subscribe(
      (response: any) => {
        console.log(response);
        this.products = response.data;
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
      }
    )
  }


}
