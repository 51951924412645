<div [ngClass]="{'sideMenu': translate.is_arabic, 'sideMenuEn': !translate.is_arabic}">
  <div class="header sticky-top">
    <p>{{translate.data.result.filttring}}</p>
  </div>
  <div class="body">

    <div>
      <p class="checktitle">{{translate.data.store.getProduct}}</p>
      <div class="d-flex">
        <div class="form-check">
          <label class="form-check-label" for="flexCheckChecked">
            {{translate.data.store.buy}}
          </label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="Is_Rent" (change)="handelChangeOwnProduct('rent')">
        </div>
        <div class="form-check mx-4">
          <label class="form-check-label" for="flexCheckChecked">
            {{translate.data.store.rent}}
          </label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="Is_Sale" (change)="handelChangeOwnProduct('sale')">
        </div>
      </div>
    </div>


    <div class="block">
      <p class="label">{{translate.data.store.additionalService}}</p>
      <div class="row flex-wrap justify-content-between">

        <div class="cardAll" value="الكل" (click)="handleAllType()">
          <div class="parentDiv">
            <p class="parentDiv">{{translate.is_arabic ? 'الكل' : 'All'}}</p>
          </div>
        </div>

        <div class="cards"
             *ngFor="let type of serviceTypesResponse"
              (click)="handleType(type)"
              [ngClass]="{'active': type.active}">
          <div class="parentDiv"><img class="parentDiv" loading="lazy" src={{sharedService.ipRootImg+type?.imagePath}} alt=""></div>
            <p class="parentDiv">{{type ? type[translate.data.requestdData.genName] : 'N/A' }}</p>
        </div>
        <div>
          <p class="checktitle">{{translate.data.store.priceAtNight}}</p>
          <p-slider [(ngModel)]="rangeValues2" [range]="true" [min]="0" [max]="1000" (onChange)="sliderPrice()"></p-slider>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="ele">
              <label for="">{{translate.data.store.lowerPrice}}</label>
              <input type="text"  [(ngModel)]='price1' (change)="handlePrice()" >
            </div>
            <div class="ele">
              <label for="">{{translate.data.store.HigherPrice}}</label>
              <input type="text" [(ngModel)]='price2' (change)="handlePrice()" >
            </div>
          </div>

        </div>


      <div>

      <div>
          <p class="checktitle">{{translate.data.store.ratting}}</p>
          <div class="form-check">
            <input class="form-check-input"
             type="checkbox"
             id="flexCheckChecked"
             [(ngModel)]="isCheckedRate5"
             (change)="handelChangeRate(isCheckedRate5, 'six')"
             style="margin: 7px -17px;">
           <!--  <p-rating [(ngModel)]="val4" [cancel] = "false"></p-rating> -->
           <star-rating [value]="val5"
                      [totalstars]="5"
                      checkedcolor="#ed6c37"
                      uncheckedcolor="rgb(224, 224, 224)"
                      size="24px"
                      [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check">
            <input class="form-check-input"
             type="checkbox"
            [(ngModel)]="isCheckedRate4"
            (change)="handelChangeRate(isCheckedRate4, 'five')"
            id="flexCheckChecked"
            style="margin: 7px -17px;">
           <!--  <p-rating [(ngModel)]="val4" [cancel] = "false"></p-rating> -->
           <star-rating [value]="val4"
                      [totalstars]="5"
                      checkedcolor="#ed6c37"
                      uncheckedcolor="rgb(224, 224, 224)"
                      size="24px"
                      [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check">
            <input class="form-check-input"
             type="checkbox"
             id="flexCheckChecked"
             style="margin: 7px -17px;"
             (change)="handelChangeRate(isCheckedRate3, 'fourth')"
            [(ngModel)]="isCheckedRate3">
            <!-- <p-rating [(ngModel)]="val3" [cancel] = "false"></p-rating> -->
            <star-rating [value]="val3"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="24px"
                        [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check">
            <input class="form-check-input"
                   type="checkbox"
                   id="flexCheckChecked"
                   [(ngModel)]="isCheckedRate2"
                   (change)="handelChangeRate(isCheckedRate2, 'third')"
                    style="margin: 7px -17px;">
            <!-- <p-rating [(ngModel)]="val2" [cancel] = "false"></p-rating> -->
            <star-rating [value]="val2"
                          [totalstars]="5"
                          checkedcolor="#ed6c37"
                          uncheckedcolor="rgb(224, 224, 224)"
                          size="24px"
                          [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check">
            <input class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="isCheckedRate1"
                  (change)="handelChangeRate(isCheckedRate1, 'second')"
                  id="flexCheckChecked"
                  style="margin: 7px -17px;">
            <star-rating [value]="val1"
                          [totalstars]="5"
                          checkedcolor="#ed6c37"
                          uncheckedcolor="rgb(224, 224, 224)"
                          size="24px"
                          [readonly]="true">
            </star-rating>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

