import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {


  products: any[];
  constructor(private spinner: NgxSpinnerService,
    public translate: TranslationService,
     private pagesService: PagesService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.pagesService.filterSearchProductObject.subscribe(
      (searchObject: any) => {
        console.log('search object => ', searchObject);
        this.pagesService.productFilter(searchObject).subscribe(
          (response: any) => {
            console.log('response data == >', response);
            this.products = response.data;

            console.log('products == >', response);
          },
          (error) => {
            console.log(error)
          }
        )
      }
    )
  }

}
